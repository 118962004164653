import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');
const searchDomain = EnvVars('API_SEARCH_BASE_URL');
const mobigestorPublic = EnvVars('API_PAINEL_DIGITAL_BASE_URL_PUBLIC');
const serviceName = 'DealerServices';

export default class DealerServices {
  static getSellers = (dealerId, proposalId) => {
    return request
      .get(
        `${domain}/dealer/v1.0/${dealerId}/sellers${
          proposalId ? `?proposalId=${proposalId}` : ''
        }`
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getSellers()`));
  };

  static getLocality = dealerId => {
    return request
      .get(`${domain}/dealer/${dealerId}/locality`)
      .then(buildHTTPResponseHandler(`${serviceName}.getLocality()`));
  };

  static createLocality = (dealerId, dealerPJLocalityDTO) => {
    return request
      .post(`${domain}/dealer/${dealerId}/locality/`, dealerPJLocalityDTO)
      .then(buildHTTPResponseHandler(`${serviceName}.createLocality()`));
  };

  static updateLocality = (dealerId, dealerLocalityId, dealerPJLocalityDTO) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/locality/${dealerLocalityId}`,
        dealerPJLocalityDTO
      )
      .then(buildHTTPResponseHandler(`${serviceName}.updateLocality()`));
  };

  static deleteLocality = (dealerId, dealerLocalityId) => {
    return request
      .delete(`${domain}/dealer/${dealerId}/locality/${dealerLocalityId}`)
      .then(buildHTTPResponseHandler(`${serviceName}.deleteLocality()`));
  };

  static getSalesAssistant = dealerId => {
    return request
      .get(`${domain}/dealer/v1.0/${dealerId}/sales-assistant`)
      .then(buildHTTPResponseHandler(`${serviceName}.getSalesAssistant()`));
  };

  static getUsers = dealerId => {
    return request
      .get(`${domain}/dealer/v1.0/${dealerId}/users`)
      .then(buildHTTPResponseHandler(`${serviceName}.getUsers()`));
  };

  static getUsersPermissionsOptions = dealerId => {
    return request
      .get(`${domain}/dealer/${dealerId}/profile/v1.0/options`)
      .then(
        buildHTTPResponseHandler(`${serviceName}.getUsersPermissionsOptions()`)
      );
  };

  static getProfilesByUser = (dealerId, userSiteId) => {
    return request
      .get(`${domain}/dealer/${dealerId}/profile/v1.0/${userSiteId}`)
      .then(buildHTTPResponseHandler(`${serviceName}.getProfilesByUser()`));
  };

  static postUsersPermissions = (dealerId, userSiteId, data) => {
    return request
      .post(`${domain}/dealer/${dealerId}/profile/v1.0/${userSiteId}`, data)
      .then(buildHTTPResponseHandler(`${serviceName}.getProfilesByUser()`));
  };

  static getDealerById = dealerId => {
    return request
      .get(`${searchDomain}/dealer/v1.0/${dealerId}`)
      .then(buildHTTPResponseHandler(`${serviceName}.getDealerById()`));
  };

  static getDealers = () => {
    return request
      .get(`${domain}/dealer/v1.0/dealers`)
      .then(buildHTTPResponseHandler(`${serviceName}.getDealers()`));
  };

  static getDealersSameGroup = dealerId => {
    return request
      .get(`${domain}/dealer/v1.0/dealers-same-group/${dealerId}`)
      .then(buildHTTPResponseHandler(`${serviceName}.getDealersSameGroup()`));
  };

  static getDealersSameMainGroup = dealerId => {
    return request
      .get(`${domain}/dealer/v1.0/dealers-same-main-group/${dealerId}`)
      .then(
        buildHTTPResponseHandler(`${serviceName}.getDealersSameMainGroup()`)
      );
  };

  static unregisterPushDevice = registration => {
    return request
      .delete(`${domain}/dealer/v1.0/${registration}`)
      .then(buildHTTPResponseHandler(`${serviceName}.unregisterPushDevice()`));
  };

  static updateDealer = (dealerId, data) => {
    return request
      .put(`${domain}/dealer/v1.0/${dealerId}`, data)
      .then(buildHTTPResponseHandler(`${serviceName}.updateDealer()`));
  };

  static activateEmail = (dealerId, activationCode) => {
    return request
      .post(
        `${domain}/dealer/v1.0/${dealerId}/${activationCode}/activate-email`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.activateEmail()`));
  };

  static activateDealer = data => {
    return request
      .post(`${mobigestorPublic}/api/v1.0/activation`, data)
      .then(buildHTTPResponseHandler(`${serviceName}.activateDealer()`));
  };

  static activateDealerSimple = data => {
    return request
      .post(`${mobigestorPublic}/api/v1.0/activation-simple`, data)
      .then(buildHTTPResponseHandler(`${serviceName}.activateDealer()`));
  };

  static updateDealerPresentation = (dealerId, data) => {
    return request
      .post(`${domain}/dealer/v1.0/${dealerId}/presentation`, data)
      .then(
        buildHTTPResponseHandler(`${serviceName}.updateDealerPresentation()`)
      );
  };

  static addUsers = (dealerId, data) => {
    return request
      .put(`${domain}/dealer/v1.0/${dealerId}/users`, data)
      .then(buildHTTPResponseHandler(`${serviceName}.addUsers()`));
  };

  static registerPushDevice = data => {
    return request
      .post(`${domain}/dealer/v1.0/registerpushdevice`, data)
      .then(buildHTTPResponseHandler(`${serviceName}.registerPushDevice()`));
  };

  static getCadastro = id => {
    return request
      .get(`${domain}/dealer/v1.0/dealer/${id}`)
      .then(buildHTTPResponseHandler(`${serviceName}.getCadastro()`));
  };

  static updateApresentationDealer = (dealerId, data) => {
    return request
      .put(`${domain}/dealer/v1.0/${dealerId}/presentation`, data)
      .then(
        buildHTTPResponseHandler(`${serviceName}.updateApresentationDealer()`)
      );
  };
  static doAuthentication = (dealerId, obj) => {
    return request
      .post(`${domain}/dealer/v1.0/${dealerId}/users`, obj)
      .then(buildHTTPResponseHandler(`${serviceName}.doAuthentication()`));
  };

  static searchDealersByConsultor = options => {
    let params = {};
    if (options) params = { ...options };
    return request
      .get(`${domain}/dealer/v1.0/dealers/consultor`, params)
      .then(
        buildHTTPResponseHandler(`${serviceName}.searchDealersByConsultor()`)
      );
  };

  static createUser = (dealerId, user) => {
    return request
      .put(`${domain}/dealer/v1.0/${dealerId}/user`, { ...user })
      .then(buildHTTPResponseHandler(`${serviceName}.createUser()`));
  };

  static updateUser = (dealerId, user) => {
    return request
      .put(`${domain}/dealer/v1.0/${dealerId}/user`, { ...user })
      .then(buildHTTPResponseHandler(`${serviceName}.updateUser()`));
  };

  static deleteUser = (dealerId, userId) => {
    return request
      .delete(`${domain}/dealer/v1.0/${dealerId}/user/${userId}`)
      .then(buildHTTPResponseHandler(`${serviceName}.deleteUser()`));
  };

  static getContacts = (
    dealerId,
    { page, size, personType, query, sellerId }
  ) => {
    const params = { page, size, personType, sellerId: sellerId, q: query };
    return request
      .get(`${domain}/dealer/v1.0/${dealerId}/contacts`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getContacts()`));
  };

  static deleteContact = (dealerId, contactId) => {
    return request
      .delete(`${domain}/dealer/v1.0/${dealerId}/contact/${contactId}`)
      .then(buildHTTPResponseHandler(`${serviceName}.deleteContact()`));
  };

  static getSuppliers = (dealerId, { page, size, personType, query }) => {
    const params = { page, size, personType, q: query };
    return request
      .get(`${domain}/dealer/v1.0/${dealerId}/suppliers`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getSuppliers()`));
  };

  static getDealerConversionPerProvider(dealerId, initialDate, finalDate) {
    const params = { finalDate, initialDate };
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposals/v1.0/reports/conversion-provider`,
        params
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getDealerConversionPerProvider()`
        )
      );
  }

  static getDealerProposalPerOrigin(dealerId, initialDate, finalDate) {
    const params = { finalDate, initialDate };
    return request
      .get(`${domain}/dealer/${dealerId}/proposals/v1.0/report/origin`, params)
      .then(
        buildHTTPResponseHandler(`${serviceName}.getDealerProposalPerOrigin()`)
      );
  }

  static getDealerProposalFinishDetail(dealerId, initialDate, finalDate) {
    const params = { finalDate, initialDate };
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposals/v1.0/reports/finish-detail`,
        params
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getDealerProposalFinishDetail()`
        )
      );
  }

  static getDealerProposalPerProvider(dealerId, initialDate, finalDate) {
    const params = { finalDate, initialDate };
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposals/v1.0/reports/provider`,
        params
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getDealerProposalPerProvider()`
        )
      );
  }

  //DEPARTMENT
  static getAllDepartamentByDealer(dealerId) {
    return request
      .get(`${domain}/dealer/${dealerId}/department/v1.0`, {})
      .then(
        buildHTTPResponseHandler(`${serviceName}.getAllDepartamentByDealer()`)
      );
  }

  static updateDepartmentByProposalId(dealerId, proposalId, departmentId) {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/department/${departmentId}`,
        {}
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.updateDepartmentByProposalId()`
        )
      );
  }

  //Activate/Inactivate User
  static manageActiveUser(dealerId, userId, activate) {
    return request
      .get(
        `${domain}/dealer/v1.0/${dealerId}/activate-inactivate/${userId}?activate=${activate}`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.manageActiveUser()`));
  }

  //CurrentUserInfosByDealerId
  static getCurrentUserInfosByDealerId(dealerId) {
    return request
      .get(`${domain}/dealer/v1.1/${dealerId}/me`, {})
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getCurrentUserInfosByDealerId()`
        )
      );
  }

  //Get history of notification per user
  static getHistoryNotificationPerUser(
    dealerId,
    userId,
    dateStart,
    dateFinish,
    params
  ) {
    return request
      .get(
        `${domain}/dealer/v1.0/notification/${dealerId}/user/${userId}/history/${dateStart}/${dateFinish}`,
        params
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getHistoryNotificationPerUser()`
        )
      );
  }
  //EXTERNAL
  static getExternalMapping(dealerId, userId) {
    return request
      .get(`${domain}/dealer/v1.0/${dealerId}/external-mapping/${userId}`)
      .then(buildHTTPResponseHandler(`${serviceName}.GetExternalMapping()`));
  }

  static syncExternalMapping(dealerId, userId, provider) {
    return request
      .put(
        `${domain}/dealer/v1.0/${dealerId}/external-mapping/${userId}/${provider}`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.SyncExternalMapping()`));
  }
  static deleteExternalMapping(dealerId, userId, provider, id) {
    return request
      .delete(
        `${domain}/dealer/v1.0/${dealerId}/external-mapping/${userId}/${provider}/${id}`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.deleteExternalMapping()`));
  }

  static checkIfDealerUserHasAnyLeads(dealerId, userId) {
    return request
      .get(`${domain}/dealer/v1.0/${dealerId}/user/${userId}/checkLeads`, {})
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.checkIfDealerUserHasAnyLeads()`
        )
      );
  }

  static getAllTags(dealerId) {
    return request
      .get(`${domain}/dealer/${dealerId}/tag/v1.0`, {})
      .then(buildHTTPResponseHandler(`${serviceName}.getAllTags()`));
  }

  static getTopTenMostUsedTags(dealerId) {
    return request
      .get(`${domain}/dealer/${dealerId}/tag/v1.0/topten`, {})
      .then(buildHTTPResponseHandler(`${serviceName}.getTopTenMostUsedTags()`));
  }

  static createTag(dealerId, data) {
    return request
      .post(`${domain}/dealer/${dealerId}/tag/v1.0`, data)
      .then(buildHTTPResponseHandler(`${serviceName}.createTag()`));
  }

  static deleteTag(dealerId, idTag) {
    return request
      .delete(`${domain}/dealer/${dealerId}/tag/v1.0/${idTag}`)
      .then(buildHTTPResponseHandler(`${serviceName}.deleteTag()`));
  }

  static updateTag(dealerId, id, objTag) {
    return request
      .put(`${domain}/dealer/${dealerId}/tag/v1.0/${id}`, objTag)
      .then(buildHTTPResponseHandler(`${serviceName}.deleteTag()`));
  }

  static getDownpaymentPartners(dealerId) {
    return request
      .get(`${domain}/dealer/${dealerId}/down-payment/v1.0/`, {})
      .then(
        buildHTTPResponseHandler(`${serviceName}.getDownpaymentPartners()`)
      );
  }

  static getDownPaymentSimulations(dealerId, proposalId) {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposals/down-payment/v1.0/${proposalId}`
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.getDownPaymentSimulations()`)
      );
  }

  static getNonAttendanceReasons(dealerId) {
    return request
      .get(`${domain}/dealer/${dealerId}/v1.0/reasons`)
      .then(
        buildHTTPResponseHandler(`${serviceName}.getNonAttendanceReasons()`)
      );
  }
}
