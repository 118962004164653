import React, { useState } from 'react';
import { ReactComponent as Trash } from 'assets/trash-gray.svg';
import routesPath from 'config/routesPath';
import RemoveVehicles from 'components/RemoveVehicles';
import formatAmount from 'helpers/formatAmount';

import {
  LinkExternalStyled,
  TitleLine,
  VehicleTitle,
  VehicleCardContainer,
  Line,
  Label,
} from './styles';
import Wrapper from 'components/Wrapper';
import RoomOutlined from '@material-ui/icons/RoomOutlined';
import StoreIcon from '@material-ui/icons/Store';

const VehicleInfos = ({ vehicle, handleClickUpdate, vehicleNumber }) => {
  const [state, setState] = useState({
    openModalRemoveVehicle: false,
    itemSelectedRemoveVehicle: null,
  });

  const handleClickOpenModalRemoveVehicle = () => {
    setState(prevState => ({
      ...prevState,
      itemSelectedRemoveVehicle: vehicle.dealId,
      openModalRemoveVehicle: !state.openModalRemoveVehicle,
    }));
  };

  const handleClickCloseModalRemoveVehicle = () => {
    setState(prevState => ({
      ...prevState,
      itemSelectedRemoveVehicle: null,
      openModalRemoveVehicle: false,
    }));
    handleClickUpdate();
  };

  return (
    <VehicleCardContainer>
      <TitleLine>
        <VehicleTitle>VEÍCULO {vehicleNumber + 1}</VehicleTitle>
        <Trash
          style={{ fontSize: '12px' }}
          onClick={handleClickOpenModalRemoveVehicle}
        />
      </TitleLine>
      {vehicle?.link ? (
        <LinkExternalStyled
          target={vehicle?.link ? '_blank' : '_self'}
          hasLink
          href={
            vehicle?.link
              ? vehicle?.link
              : routesPath.getRecursivePath(routesPath.updateDeal, {
                  dealId: vehicle?.dealId,
                })
          }
        >
          {vehicle?.title}
        </LinkExternalStyled>
      ) : (
        <>
          {vehicle?.title && (
            <Line style={{ fontSize: 16 }}>{vehicle?.title}</Line>
          )}
          {(vehicle?.make?.name ||
            vehicle?.model?.name ||
            vehicle?.trim?.name) && (
            <Line style={{ fontSize: 16 }}>
              {vehicle?.make?.name || ''} {vehicle?.model?.name || ''}{' '}
              {vehicle?.trim?.name || ''}
            </Line>
          )}
        </>
      )}
      {vehicle?.km && (
        <Line>
          <Label>KM Máxima:</Label>
          <div>{formatAmount(vehicle?.km)}</div>
        </Line>
      )}
      {!!vehicle?.price && (
        <Line>
          <Label>Preço:</Label>
          <div>{formatAmount(vehicle?.price, 'PT-BR', 'R$', true)}</div>
        </Line>
      )}

      {!!vehicle?.dealerPJLocality && (
        <>
          <Wrapper flex align="center" gap={4} color="#757575">
            <StoreIcon fontSize="small" />
            <Label>{`${vehicle?.dealerPJLocality?.address}, ${vehicle?.dealerPJLocality?.number}`}</Label>
          </Wrapper>
          <Wrapper flex align="center" gap={4} color="#757575">
            <RoomOutlined fontSize="small" />
            <Label>
              {`${vehicle?.dealerPJLocality?.cityName}`}{' '}
              {vehicle?.dealerPJLocality?.stateName &&
                `- ${vehicle?.dealerPJLocality?.stateName}`}
            </Label>
          </Wrapper>
        </>
      )}
      {vehicle?.minPrice && (
        <Line>
          <Label>Preço Mínimo:</Label>
          <div>{formatAmount(vehicle.minPrice, 'PT-BR', 'R$', true)}</div>
        </Line>
      )}
      {vehicle?.maxPrice && (
        <Line>
          <Label>Preço Máximo:</Label>
          <div>{formatAmount(vehicle?.maxPrice, 'PT-BR', 'R$', true)}</div>
        </Line>
      )}
      {vehicle?.fuel?.name && (
        <Line>
          <Label>Combustível:</Label>
          <div>{vehicle?.fuel?.name}</div>
        </Line>
      )}
      {vehicle.color?.name && (
        <Line>
          <Label>Cor:</Label>
          <div>{vehicle?.color?.name}</div>
        </Line>
      )}
      {vehicle?.transmission?.name && (
        <Line>
          <Label>Transmissão:</Label>
          <div>{vehicle?.transmission?.name}</div>
        </Line>
      )}
      {vehicle?.bodystyle?.name && (
        <Line>
          <Label>Carroceria:</Label>
          <div>{vehicle?.bodystyle?.name}</div>
        </Line>
      )}
      <RemoveVehicles
        open={state.openModalRemoveVehicle}
        itemSelected={state.itemSelectedRemoveVehicle}
        handleClose={handleClickCloseModalRemoveVehicle}
        vehicle={vehicle}
      />
    </VehicleCardContainer>
  );
};

export default VehicleInfos;
