import styled from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

import MUIButton from '@material-ui/core/Button';
import { withStyles, CreateCSSProperties } from '@material-ui/styles';
import { Props as WrapperProps } from 'components/Wrapper/Wrapper.types';

export const wrapperStyle: WrapperProps = {
  fontWeight: 500,
  fontSize: 14,
  color: '#424242',
  ml: 3,
  lineHeight: '17px',
  flex: true,
  alignSelf: 'center',
};

export const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: center;
  text-align: center;
`;

export const Divider = styled.div`
  display: block;
  margin: 0.5rem 0;
  width: 100%;

  &:before {
    content: '';
    background-color: #e0e0e0;
    width: 100%;
    height: 1px;
    display: block;
  }
`;

export const Line = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Poppins Regular, Roboto, Helvetica, Arial, sans-serif;
`;

export const LineEnd = styled(Line)`
  align-items: flex-end;
  font-family: Poppins Bold, Roboto, Helvetica, Arial, sans-serif;
`;

export const FieldCss = styled(Line)`
  flex-direction: column;
  align-items: initial;
  font-family: Poppins Regular, Roboto, Helvetica, Arial, sans-serif;
`;

export const LineBottom = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
  margin: 8px 0;
  align-items: flex-end;
  font-family: Poppins Regular, Roboto, Helvetica, Arial, sans-serif;
`;

export const PlateBox = styled.div`
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  height: 128px;
  background-color: ${Colors.primary.light}19;
`;

export const Label = styled.div`
  font-family: Poppins Regular, Roboto, Helvetica, Arial, sans-serif;
  color: ${Colors.gray.dark};
  font-size: 12px;
  text-align: left;
  b: {
    font-family: Poppins Bold, Roboto, Helvetica, Arial, sans-serif;
    font-weight: bold;
  }
`;

export const Container = styled.div`
  font-family: Poppins Bold;
  font-weight: 600;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  padding: 20px 13px;
  margin: 15px 16px;
  font-family: 'Poppins Medium';
  font-size: 14px;
`;

export const DivLine = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: ${props => props.margin ?? ''};
`;

export const ButtonLineLaudo = styled.div`
  color: ${props => (props.disabled ? '#BDBDBD' : '#4443BC')};
  margin: ${props =>
    props.disabled ? '0px 6px 1px auto' : '-15px 6px 1px auto'};
  padding: 0;
  cursor: pointer;
`;

export const Plate = styled.div`
  text-align: center;
  text-align: center;
  background: #f5f5f5;
  color: #757575;
  margin-right: 10px;
  width: 74px;
  height: 30px;
  font-size: 12px;
`;

export const TitleCardWrapper = styled.div``;

export const StyleTitleCard = styled.div`
  color: #424242;
  font-weight: 700;
  font-size: 16px;
  margin-right: 31px;
  line-height: 19px;
`;

export const Version = styled.div`
  color: #424242;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;

export const TextWrapper = styled.div`
  display: flex;
  width: ${props => (props.firstItem ? '132px' : '')};
  flex: ${props => (props.firstItem ? '' : '1')};
`;

export const LabelText = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #757575;
  height: 30px;
  padding-right: 8px;
  width: min-content;
  text-wrap: nowrap;
`;

export const ValueStyle = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #424242;
  margin-left: 3px;
`;

export const ReferenceBox = styled.div`
  width: 100%;
  float: left;
  margin: 1rem auto;

  .title {
    font-family: 'Poppins Medium';
    font-size: 0.875rem;
    font-weight: 500;
  }

  .item {
    background: transparent;
    padding: 0;

    &:not(:last-child) {
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 0.5rem;
    }
  }
`;

export const ButtonsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  margin-top: 1rem;

  button {
    flex: 1 auto;
  }
`;

const bottomButtonsStyles: CreateCSSProperties<{}> = {
  height: '2.5rem',
  fontSize: '0.875rem',
  fontFamily: 'Poppins Medium',
  fontWeight: 500,
  textTransform: 'none',
  boxShadow: 'none',
  padding: '0 0.75rem',
};

export const LaudoButton = withStyles(() => ({
  root: {
    ...bottomButtonsStyles,
    color: '#4443BC',
    backgroundColor: 'transparent',
    border: '1px solid #4443BC',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))(MUIButton);


export const RequestButton = withStyles(() => ({
  root: {
    ...bottomButtonsStyles,
    color: '#FFF',
    backgroundColor: '#00B4AA',
    '&:hover': {
      backgroundColor: '#00B4AA',
    },
  },
}))(MUIButton);

export const DescriptionWrapper = styled('div')`
  display: inline-block;
`;

export const DescriptionTitle = styled('b')`
  display: inline-block;
  margin: 8px 0;
  font-size: 0.875rem;
  color: #424242;
  font-family: 'Poppins Medium';
  font-weight: 500;
`;

export const DescriptionItem = styled('div')`
  font-family: 'Poppins Regular';
  font-weight: 400;
  color: #424242;
  line-height: 24px;
`;

export const TooltipTitle = styled.h1`
  font-family: Poppins Medium;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
`;
