import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import {
  ANCHOR_LEFT,
  OPEN_UP,
  ICON_AFTER_POSITION,
} from 'react-dates/constants';
import ButtonBase from '@material-ui/core/ButtonBase';
import { withStyles } from '@material-ui/styles';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import RadioButton from 'bit/mobiauto.web-ui-components.components.radio-button/RadioButton';
import OutlinedButton from 'components/atoms/OutlinedButton';
import ThemeButton from 'components/atoms/ThemeButton';
import { DATES_BY_PERIOD } from 'helpers/predefinedDates';
import * as S from './styles';

interface DateRange {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

function DateFilter({
  currentStartDate = null,
  currentEndDate = null,
  handleClickFilter,
  classes,
  monthOptions = [
    'currentMonth',
    'lastMonth',
    // 'lastThreeMonth',
    // 'lastTwelveMonth',
  ],
  daysOptions = [
    'today',
    'yesterDay',
    'yesterDayAndToday',
    'sevenDays',
    'fifteenDays',
    'thirtyDays',
    // 'sixtyDays',
    'ninetyDays',
  ],
  currentFilterSelected = null,
  hasDataRange = true,
  hasDaysOptions = true,
  hasMonthOptions = true,
  disabled = false,
  isLargeSize = false,
  onFilterChange = null,
  error = null,
}) {
  const [startDate, setStartDate] = useState<any>(
    currentStartDate
      ? moment(currentStartDate)
      : DATES_BY_PERIOD?.['currentMonth'].startDate
  );
  const [endDate, setEndDate] = useState<any>(
    currentEndDate
      ? moment(currentEndDate)
      : DATES_BY_PERIOD?.['currentMonth'].endDate
  );
  const [focusedInput, setFocusedInput] = useState();
  const [singleFocusedInput, setSingleFocusedInput] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(
    currentFilterSelected && currentFilterSelected !== 'personalized'
      ? DATES_BY_PERIOD?.[currentFilterSelected]?.id
      : currentEndDate
      ? null
      : 'currentMonth'
  );

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    setStartDate(moment(currentStartDate));
    setEndDate(moment(currentEndDate));
  }, [currentStartDate, currentEndDate]);

  const handleOnClickDateFilter = option => {
    setSelectedFilter(DATES_BY_PERIOD?.[option].id);
    setStartDate(DATES_BY_PERIOD?.[option].startDate);
    setEndDate(DATES_BY_PERIOD?.[option].endDate);

    if (onFilterChange)
      onFilterChange({
        initialDateTimeStemp: DATES_BY_PERIOD?.[option].startDate.valueOf(),
        finalDateTimeStemp: DATES_BY_PERIOD?.[option].endDate.valueOf(),
      });
  };

  const handleOnChangeDateFilter = <T extends moment.Moment | DateRange>(
    date: T,
    type: 'SINGLE' | 'RANGE'
  ) => {
    let startDateValue, endDateValue;
    const dateValue = date as DateRange;

    const startValue = {
      hour: 0,
      minute: 0,
      second: 0,
    };

    const endValue = {
      hour: 23,
      minute: 59,
      second: 59,
    };

    if (type === 'SINGLE') {
      startDateValue = moment.isMoment(date) ? date.set(startValue) : null;
      endDateValue = moment.isMoment(date) ? date.set(endValue) : null;
    } else if (type === 'RANGE') {
      startDateValue = dateValue?.startDate?.set(startValue);
      endDateValue = dateValue?.endDate?.set(endValue);
    }

    setStartDate(startDateValue || '');
    setEndDate(endDateValue || '');
    setSelectedFilter('personalized');

    if (onFilterChange) {
      onFilterChange({
        initialDateTimeStemp: moment.isMoment(startDateValue)
          ? startDateValue?.valueOf()
          : null,
        finalDateTimeStemp: moment.isMoment(startDateValue)
          ? endDateValue?.valueOf()
          : null,
      });
    }
  };

  return (
    <Fragment>
      <ButtonBase
        classes={{ root: classes.root }}
        style={{ maxHeight: '57px' }}
        onClick={handleClick}
        disabled={disabled}
      >
        <S.DateFilterButton
          data-is-large-size={isLargeSize}
          data-has-error={error}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CalendarTodayOutlinedIcon className="svg-calendar-icon" />
            {DATES_BY_PERIOD?.[selectedFilter]?.label ||
              `${moment(startDate).format('DD/MM')} - ${moment(endDate).format(
                'DD/MM'
              )}`}
          </div>
          {open ? (
            <KeyboardArrowDownOutlinedIcon className="svg-arrow-icon" />
          ) : (
            <KeyboardArrowUpOutlinedIcon className="svg-arrow-icon" />
          )}
        </S.DateFilterButton>
      </ButtonBase>
      <S.Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ paper: classes.popover }}
        className={focusedInput || singleFocusedInput ? 'open-date-filter' : ''}
      >
        <S.Container>
          {hasMonthOptions && (
            <Fragment>
              <div className="title">Selecionar período</div>
              <div className="filterButtons">
                {monthOptions.map((option, index) => (
                  <ButtonBase
                    key={`${option}-${index}`}
                    onClick={() => handleOnClickDateFilter(option)}
                  >
                    <div className="filterItem">
                      <RadioButton
                        checked={
                          selectedFilter === DATES_BY_PERIOD?.[option].id
                        }
                      />
                      {DATES_BY_PERIOD?.[option].label}
                    </div>
                  </ButtonBase>
                ))}
              </div>
            </Fragment>
          )}
          {hasDaysOptions && (
            <>
              <div className="title">Filtrar por</div>
              <div className="filterButtons">
                {daysOptions.map(option => (
                  <>
                    <ButtonBase onClick={() => handleOnClickDateFilter(option)}>
                      <div className="filterItem">
                        <RadioButton
                          checked={
                            selectedFilter === DATES_BY_PERIOD?.[option].id
                          }
                        />
                        {DATES_BY_PERIOD?.[option].label}
                      </div>
                    </ButtonBase>
                  </>
                ))}
              </div>
            </>
          )}
          {hasDataRange && (
            <>
              <div className="title">Selecione por dia</div>
              <div className="dataRangeContainer">
                <SingleDatePicker
                  date={startDate}
                  numberOfMonths={1}
                  onOutsideClick={true}
                  onDateChange={date =>
                    handleOnChangeDateFilter(date, 'SINGLE')
                  }
                  focused={singleFocusedInput}
                  onFocusChange={({ focused }) => {
                    setSingleFocusedInput(focused);
                  }}
                  openDirection={OPEN_UP}
                  inputIconPosition={ICON_AFTER_POSITION}
                  small
                  isOutsideRange={() => false}
                  customInputIcon={
                    <CalendarTodayOutlinedIcon
                      style={{
                        fill: '#82888a',
                        height: 15,
                        width: 14,
                        verticalAlign: 'middle',
                      }}
                    />
                  }
                />
              </div>
            </>
          )}
          {hasDataRange && (
            <>
              <div className="title">Selecione por período</div>
              <div className="dataRangeContainer" style={{ marginBottom: 8 }}>
                <DateRangePicker
                  id="range-calendar"
                  startDate={startDate || ''}
                  startDateId="start-date"
                  endDate={endDate || ''}
                  endDateId="end-date"
                  onDatesChange={({ startDate, endDate }) =>
                    handleOnChangeDateFilter({ startDate, endDate }, 'RANGE')
                  }
                  focusedInput={focusedInput}
                  onFocusChange={focusedInput => {
                    setFocusedInput(focusedInput);
                  }}
                  anchorDirection={ANCHOR_LEFT}
                  openDirection={OPEN_UP}
                  startDatePlaceholderText="Data Inicial"
                  endDatePlaceholderText="Data Final"
                  small
                  showDefaultInputIcon
                  inputIconPosition={ICON_AFTER_POSITION}
                  isOutsideRange={() => false}
                  customInputIcon={
                    <CalendarTodayOutlinedIcon
                      style={{
                        fill: '#82888a',
                        height: 15,
                        width: 14,
                        verticalAlign: 'middle',
                      }}
                    />
                  }
                />
              </div>
            </>
          )}
          {!onFilterChange && (
            <div className="actionButtons">
              <Fragment>
                <OutlinedButton
                  onClick={() => {
                    setStartDate(DATES_BY_PERIOD?.['currentMonth'].startDate);
                    setEndDate(DATES_BY_PERIOD?.['currentMonth'].endDate);
                    setSelectedFilter(DATES_BY_PERIOD?.['currentMonth'].id);
                  }}
                >
                  Limpar Filtros
                </OutlinedButton>
                <div style={{ width: 8 }} />
                <ThemeButton
                  onClick={() => {
                    handleClickFilter({
                      initialDate: startDate,
                      finalDate: endDate,
                      initialDateTimeStemp: moment(startDate).valueOf(),
                      finalDateTimeStemp: moment(endDate).valueOf(),
                      filterType: selectedFilter,
                    });
                    handleClose();
                  }}
                >
                  Filtrar
                </ThemeButton>
              </Fragment>
            </div>
          )}
        </S.Container>
      </S.Popover>
    </Fragment>
  );
}

export default withStyles(S.styles)(DateFilter);
