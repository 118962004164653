import { all, put, takeLatest, select } from 'redux-saga/effects';
import store from 'modules/resale/store/index';
import { Action } from 'types/ActionTypes';
import ActionTypes from './actionTypes';
import ActionCreators from './actionCreators';
import { BankAccount, PaymentMethod } from 'modules/financial/types';
import PaymentMethodsServices from 'modules/financial/services/PaymentMethodsServices';
import BankAccountServices from 'modules/financial/services/BankAccountServices';
import dealerServices from 'services/dealerServices';

const buildsBankAccountOptions = (bankAccounts: BankAccount[]) => {
  return bankAccounts?.length
    ? bankAccounts?.map(account => ({
        value: account.id,
        label: account.name,
      }))
    : [];
};

const buildSellersIdsOptions = (sellers: any[]) => {
  return sellers?.length
    ? sellers?.map(account => ({
        value: account.userSite.id,
        label: account.userSite.name,
      }))
    : [];
};

const buildsPaymentMethodoptions = (paymentMethods: PaymentMethod[]) => {
  return paymentMethods?.length
    ? paymentMethods.map(method => ({
        value: method.id,
        label: method.name,
      }))
    : [];
};

function* getPaymentMethodOptions() {
  const { success, data } = yield PaymentMethodsServices.list();

  success
    ? yield put(
        ActionCreators.setPaymentMethods(buildsPaymentMethodoptions(data) || [])
      )
    : console.log('tempDealer getPaymentMethodOptions() sagas HTTP error');
}

function* getSellerIds() {
  const dealerId = yield select(({ tempDealer }) => tempDealer.dealer.id);
  const { success, data } = yield dealerServices.getSellers(dealerId);

  success
    ? yield put(
        ActionCreators.setSellersIds(buildSellersIdsOptions(data) || [])
      )
    : console.log('tempDealer getPaymentMethodOptions() sagas HTTP error');
}

function* getBankAccountOptions() {
  const { success, data } = yield BankAccountServices.list();

  success
    ? yield put(
        ActionCreators.setBankAccountOptions(
          buildsBankAccountOptions(data) || []
        )
      )
    : console.log('tempDealer getPaymentMethodOptions() sagas HTTP error');
}

function* loadDealerDataOptions(action: Action) {
  yield getPaymentMethodOptions();
  yield getBankAccountOptions();
  yield getSellerIds();
}

function* getContacts(action: Action) {
  const { query, sellerId } = action.payload;

  const dealerStore = store.getState().tempDealer;
  const { success, data } = yield dealerServices.getContacts(
    dealerStore.dealer.id,
    { page: 0, query: query, size: 20, personType: null, sellerId: sellerId }
  );

  success
    ? yield put(ActionCreators.saveContacts(data || []))
    : console.log('tempDealer getContacts() sagas HTTP error');
}

export default all([
  takeLatest(ActionTypes.GET_DEALER_CONTACTS_REQUEST, getContacts),
  takeLatest(ActionTypes.LOAD_DEALER_DATA_OPTIONS, loadDealerDataOptions),
]);
