import { useSelector } from 'react-redux';

import { highAccessUserTypes } from 'constants/userAccess';
import { checkCallCenterOsSearchAndRescueRoute } from 'helpers/checkCallcenterOrSearchAndRescue';

const PRIVILEGED_EMAIL_DOMAINS = ['@mobiauto.com.br', '@grupopan.com'];

export const userPermissions = [
  'EXPORT_CSV_OVERVIEW_AD',
  'CREATE_AUTOMATION',
  'VIEW_AUTOMATION',
  'PROPOSAL_CREATE',
  'PROPOSAL_ARCHIVE',
  'PROPOSAL_CHANGE_AGENT',
  'PROPOSAL_CHANGE_STORE',
  'COPILOT',
  'PUBLISH_AUTOMATION',
  'SWITCH_DEPARTMENT_LEAD',
  'CONSULTANT',
  'VIEW_PAYMENTS_TRANSFERENCES',
  'CREATE_PAYMENTS_TRANSFERENCES',
] as const;

export type UserPermission = typeof userPermissions[number];

export function usePermissions() {
  const { user } = useSelector(state => ({ user: state?.userStore }));

  const userType = String(user?.userType ?? '');
  const userEmail = String(user?.userSite?.email ?? '');
  const userPermissions: string[] = user?.profiles ?? [];

  const isPrivilegedUserType = highAccessUserTypes.includes(userType);

  const isPrivilegedUserEmail = () => {
    const domainPattern = PRIVILEGED_EMAIL_DOMAINS.map(domain =>
      domain.replace('.', '\\.')
    ).join('|');

    const regex = new RegExp(domainPattern, 'g');

    return regex.test(userEmail);
  };

  const hasPermission = (permission: UserPermission) => {
    if (checkCallCenterOsSearchAndRescueRoute()) {
      return true;
    }
    return (
      isPrivilegedUserEmail() ||
      isPrivilegedUserType ||
      userPermissions.includes(permission)
    );
  };

  return { hasPermission };
}
