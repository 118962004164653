/* eslint-disable @typescript-eslint/interface-name-prefix */
import { useSelector } from 'react-redux';

import { select } from 'redux-saga/effects';

export type ProductNameUnion =
  | 'SCHEDULE'
  | 'SUPERSET'
  | 'LEAD_MANAGER'
  | 'EVALUATE'
  | 'SHIELDMYAD'
  | 'CAMPAIGN'
  | 'SEARCHANDRESCUE_CENTER'
  | 'CALL_CENTER'
  | 'CLASSIFIED_THIRD_PRIORITY'
  | 'CLASSIFIED_0KM_TOTAL'
  | 'CLASSIFIED_USED_TOTAL'
  | 'CLASSIFIED_OEM'
  | 'CLASSIFIED_FIRST_PRIORITY'
  | 'CLASSIFIED_SECOND_PRIORITY'
  | 'FACEBOOK_LEAD_ADS'
  | 'BIG_DEAL'
  | 'PROPOSAL_ORDER_OEM'
  | 'FINANCIAL'
  | 'FINANCING'
  | 'CONTACT'
  | 'GOALS_MANAGEMENT'
  | 'PATIO_CONTROL'
  | 'STOCK_INTEGRATOR'
  | 'STOCK_INTEGRATOR_EASYCAR'
  | 'INVENTORY'
  | 'MOBIFI'
  | 'MOBIGPT_FOR_STOCK'
  | 'MOBIGPT_FOR_LEADMANAGEMENT'
  | 'MOBIONE'
  | 'MOBIPHONE'
  | 'MEDIA'
  | 'ADVANCED_NOTIFICATIONS'
  | 'PROPOSAL_APPLICATION'
  | 'REPASSE'
  | 'REPASSE_VENDA'
  | 'SMS'
  | 'SITE'
  | 'SITE_VENDEDOR'
  | 'WHATSAPP'
  | 'MOBI_INTELIGENCIA_LEAD_360'
  | 'CORPORATIVE_SALE'
  | 'PORTAL_ATACADO';

type Product = ProductNameUnion | ProductNameUnion[];

interface IProducts {
  id: number;
  quantity: number;
  type: ProductNameUnion;
}

const useDealerProducts = () => {
  const { products } = useSelector(
    ({ dealerStore }: any) => dealerStore.currentDealer
  );
  const hasProduct = (product: Product): boolean => {
    if (product instanceof Array) {
      return product
        .map(productName => {
          return products?.some(({ type }: IProducts) => type === productName);
        })
        .includes(true);
    }

    return products?.some(({ type }: IProducts) => type === product);
  };

  return { hasProduct };
};

export function* dealerHasProductGeneration(product: Product) {
  const { products }: { products: IProducts[] } = yield select(
    (state: { dealerStore: { currentDealer: { products: IProducts[] } } }) =>
      state?.dealerStore?.currentDealer
  );

  if (!products) {
    return false;
  }

  return products?.some(item => item?.type === product);
}

export default useDealerProducts;
