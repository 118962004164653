/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { CircularProgress, useTheme } from '@material-ui/core';
import Wrapper from 'components/Wrapper';
import { OutlinedButton } from './styles';

type propTypes = {
  variant?: 'default' | 'red' | 'purple' | 'text' | 'primary';
  children: React.ReactNode;
  [restProps: string]: any;
};

const OutlinedButtonComponent = ({
  variant = 'default',
  children,
  ...props
}: propTypes): JSX.Element => {
  const { loading } = props;

  const theme = useTheme();

  return (
    <OutlinedButton variant={variant} {...props} theme={theme}>
      {loading ? (
        <CircularProgress style={{ color: 'black' }} />
      ) : (
        <Wrapper flex align="center" gap={4}>
          {children}
        </Wrapper>
      )}
    </OutlinedButton>
  );
};

export default OutlinedButtonComponent;
