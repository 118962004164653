import ProposalsListServices from 'modules/resale/services/ProposalsListServices';
import ProposalListCorporativeSaleServices from 'modules/resale/services/ProposalListCorporativeSaleServices';
import NegotiationServices from 'modules/resale/services/NegotiationServices';
import NegotiationCorporativeSaleServices from 'modules/resale/services/NegotiationCorporativeSaleServices';

const ID_BANCO_PAN = 83561;
export const ID_MOVIDA = 33686;

export function ProposalServicesToggleByDealerProducts(
  hasProductCorporativeSale: boolean
) {
  return hasProductCorporativeSale
    ? ProposalListCorporativeSaleServices
    : ProposalsListServices;
}

export function getNegotiationServicesByDealerProduct(
  hasProductCorporativeSale: boolean
) {
  return hasProductCorporativeSale
    ? NegotiationCorporativeSaleServices
    : NegotiationServices;
}

export const ownTheProductBuyItNow = ({ dealerId }) => {
  return ID_BANCO_PAN !== Number(dealerId);
};
