import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const serviceName = 'DealderContactService';
const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');

export default {
  getContactById: function(dealerId, contactId) {
    return request
      .get(`${domain}/dealer/v1.0/${dealerId}/contact/${contactId}`)
      .then(buildHTTPResponseHandler(`${serviceName}.getContactById()`));
  },

  updateContact: function(dealerId, contactId, contactData) {
    return request
      .put(
        `${domain}/dealer/v1.0/${dealerId}/contact/${contactId}`,
        contactData
      )
      .then(buildHTTPResponseHandler(`${serviceName}.updateContact()`));
  },

  createContact: function(dealerId, contactData) {
    return request
      .post(`${domain}/dealer/v1.0/${dealerId}/contact`, contactData)
      .then(buildHTTPResponseHandler(`${serviceName}.createContact()`));
  },

  syncContact: function(dealerId, contactId) {
    return request
      .get(`${domain}/dealer/v1.0/${dealerId}/contact/${contactId}/sync-dms`)
      .then(buildHTTPResponseHandler(`${serviceName}.syncContact()`));
  },

  createContactByCSV: function(dealerId, fileData) {
    return request
      .post(`${domain}/dealer/v1.0/${dealerId}/contact/import`, fileData)
      .then(buildHTTPResponseHandler(`${serviceName}.createContactByCSV()`));
  },

  getContacts: function(
    dealerId,
    { page = 0, query = null, size = 20, sellerId = null }
  ) {
    const params = {
      page,
      q: query?.length < 1 ? null : query,
      size,
      sellerId,
    };
    return request
      .get(`${domain}/dealer/v1.0/${dealerId}/contacts`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getContacts()`));
  },

  printContact: (dealerId, contactId) => {
    return request
      .get(
        `${domain}/dealer/v1.0/${dealerId}/contact/${contactId}/pdf`,
        {},
        { responseType: 'blob' }
      )
      .then(buildHTTPResponseHandler(`${serviceName}.printContact()`));
  },
};
